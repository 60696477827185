<template>
  <el-dialog
    v-loading="loading"
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="600px"
    top="15vh"
    :close-on-click-modal="false"
    destroy-on-close
    center
  >
    <div class="orderTest-container" style="width: 300px; margin: 0 auto">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="80px"
      >
        <el-form-item label="一级科目:" prop="">
          <el-input v-model="aname" disabled></el-input>
        </el-form-item>
        <el-form-item label="科目代码:" prop="bill_code">
          <el-input v-model="form.bill_code" disabled></el-input>
        </el-form-item>
        <el-form-item label="科目名称:" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item v-if="aname == '预付账款'" label="供应商:" prop="supp_id">
          <el-select
            v-model="form.supp_id"
            filterable
            placeholder="请选择供应商"
            style="width: 200px"
          >
            <el-option
              v-for="(item, index) in supplier"
              :key="index"
              :label="item.supp_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门:" prop="depart_id">
          <el-select
            v-model="form.depart_id"
            clearable
            multiple
            style="width: 200px"
            placeholder="全部"
          >
            <el-option
              v-for="(i, idx) in groupSelect"
              :key="idx"
              :value="i.id"
              :label="i.text"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态:" prop="status">
          <el-radio v-model="form.status" :label="1">正常</el-radio>
          <el-radio v-model="form.status" :label="2">停用</el-radio>
        </el-form-item>
      </el-form>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button v-if="isAdd" type="primary" @click="handleSubmit">
        确定
      </el-button>
      <el-button v-else type="primary" @click="handleEdit">确定</el-button>

      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { departmentList } from '@/api/performance'
  import { addRow, newCode, editRow } from '@/api/setting'
  import { postAction } from '@/api/Employee'
  export default {
    name: '',
    components: {},
    data() {
      return {
        title: '标题',
        loading: false,
        groupSelect: [],
        showDialog: false,
        aname: '',
        isAdd: true,
        supplier: [],
        form: {
          parent_id: '', //上级id
          supp_id: '',
          name: '', //科目名称
          status: 1, //状态1正常2停用
          bill_code: 0, //编码
          depart_id: [], //部门id,多个以,隔开
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (!val) {
          this.clearForm()
        } else {
          if (this.isAdd) {
            newCode({ parent_id: this.form.parent_id }).then((res) => {
              this.loading = true
              this.form.bill_code = res.data.code
              this.loading = false
            })
          }
        }
      },
    },
    created() {
      // 部门下拉
      departmentList().then((res) => {
        this.groupSelect = res.data.list
      })
      postAction('/purchaseAdmin/base/supp-list', {})
        .then((res) => {
          console.log(res, '供应商')
          this.supplier = res.data.rows
        })
        .catch((err) => {
          console.log(err, '')
        })
    },
    mounted() {},
    methods: {
      handleSubmit() {
        // 处理部门ID
        this.form.depart_id = this.form.depart_id.join(',')
        addRow(this.form).then((res) => {
          this.close()
          this.clearForm()
          this.$message.success(res.msg)
          this.$emit('refresh')
        })
      },
      handleEdit() {
        console.log(123)
        let did = JSON.parse(JSON.stringify(this.form.depart_id)).join(',')
        editRow({
          id: this.form.parent_id,
          name: this.form.name,
          supp_id: this.form.supp_id,
          status: this.form.status,
          depart_id: did,
        }).then((res) => {
          this.$emit('refresh')
          this.$message.success('修改成功')
          this.close()
        })
      },
      close() {
        this.showDialog = false
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.isAdd = true
        ;(this.form = {
          parent_id: '', //上级id
          name: '', //科目名称
          supp_id: '',
          status: 1, //状态1正常2停用
          bill_code: 0, //编码
          depart_id: [], //部门id,多个以,隔开
        }),
          (this.aname = '')
      },
    },
  }
</script>
<style lang="scss" scoped></style>
